<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="lg"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Mã đơn vị<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                  :custom-messages="code"
                >
                  <b-form-input
                    v-model="dataInput.code"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Tên đơn vị<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                  :custom-messages="name"
                >
                  <b-form-input
                    v-model="dataInput.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Url<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Url"
                  rules="required"
                  :custom-messages="Url"
                >
                  <b-form-input
                    v-model="dataInput.url"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Địa chỉ</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Lãnh đạo</label>
                <b-form-input
                  v-model="dataInput.leader"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  id="checkbox-affiliated"
                  v-model="dataInput.status"
                  class="mt-3"
                  name="checkbox-1"
                >
                  Trạng thái
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã số là bắt buộc',
      },
      name: {
        required: 'Tên đơn vị là bắt buộc',
      },
      Url: {
        required: 'Đường dẫn Url là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        url: '',
        leader: '',
        address: '',
        status: true,
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetail.code,
          name: this.dataDetail.name,
          url: this.dataDetail.url,
          leader: this.dataDetail.leader,
          address: this.dataDetail.address,
          status: this.dataDetail.status,
        }
      }
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        code: '',
        name: '',
        url: '',
        leader: '',
        address: '',
        status: true,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
